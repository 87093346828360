import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'Limpiarguion'
})
export class Limpiarguion implements PipeTransform {
   transform(val: string): string {

    val = val.replace(/_/g, " ");

    return val;
   }
}