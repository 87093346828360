import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customTextMapper'
})
export class CustomTextMapper implements PipeTransform {

  private textMap: { [key: string]: string } = {
    'inventoryReportsExpenses': 'Expenses',
    'inventoryReportsExtraMissing': 'Extra & Missing',
    'inventoryReports': 'Inventory Reports',

  };

  private defaultText: string = 'Title undefined'; // Texto predeterminado

  transform(value: string): string {
    return this.textMap[value] || value;
  }

}
