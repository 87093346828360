import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name:'Fechagts'})
export class Fechagts implements PipeTransform{
	transform(value): string{
		let date = new Date(value);

		let day = date.getDate();
		let final_day = day.toString();
		if(day <= 9){
			final_day = '0'+day;
		}

		let month = (date.getMonth() + 1);
		let final_month = month.toString();
		if(month <= 9){
			final_month = '0'+month;
		}

		var hours = date.getHours();
  var minutes: any = date.getMinutes();
  var ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0'+minutes : minutes;
  var strTime = hours + ':' + minutes + ' ' + ampm;

		let result = final_month + '/' + final_day + '/' + date.getFullYear() + ' ' + strTime;
		return result;

	}
}