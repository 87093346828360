import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GLOBAL } from './global';
import { Methods } from '../shared/methods';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class NotificacionesService {
  public mtds: Methods = new Methods();

  public url: string;
  public urlUser: string;
  public token;
  public headers: HttpHeaders;

  constructor(private _router: Router, private http: HttpClient) {
    this.url = GLOBAL.url + '/notification/';
    this.token = JSON.parse(localStorage.getItem('token'));
    if (this.token === 'undefined') {
      this.token = null;
    }
    this.headers = new HttpHeaders({'Content-Type' : 'application/x-www-form-urlencoded', 'X-Requested-With': 'XMLHttpRequest'});
  }

  marked(type: string, json): Observable<any> {
    return this.petition(this.url + type, json);
  }

  create(json): Observable<any> {
    return this.petition(this.url + 'customize', json);
  }

  showAll(url: string, paginate, busqueda = ''): Observable<any> {
    let json= null;
    if (busqueda !== '') {
      json = {
        q: busqueda
      };
    }
    return this.petition(this.url + url + '?page=' + paginate.page_actual + '&perpage=' + paginate.items_per_page, json);
  }

  delete(json): Observable<any> {
    return this.petition(this.url + 'remove', json);
  }

  petition(url, json = null, type = null): Observable<any> {
    this.token = JSON.parse(localStorage.getItem('token'));
    let params = 'authorization=' + this.token;

    if (json != null) {
      json = encodeURIComponent(JSON.stringify(json));
      params = params + '&json=' + json;
    }

    if (type != null) {
      params = params + '&type=' + type;
    }

    return this.http.post(url, params, {headers: this.headers})
      .pipe(map(res => {
        if (res['code'] === 404) {
          this.mtds.notifyError('Your session has expired');
          localStorage.clear();
          this._router.navigate(['/pages/lock']);
        }
        return res;
      }));
  }
}
