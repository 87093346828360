import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FolderService {
  public url: any;

  constructor(private http: HttpClient) {
    this.url = GLOBAL.url;
  }

  create(token: any, task: any): Observable<any> {
    const json = JSON.stringify(task);
    const params = {
      json: encodeURIComponent(json),
      authorization: token
    };
    const headers = this.createHeaders();
    const page = 1;

    return this.http.post<any>(
      `${this.url}/folder/new?page=${page}`,
      this.buildSearchParams(params),
      { headers }
    );
  }

  downloadFile(folderId: string, token: string, includeFiles: number): Observable<Blob> {
    const url = `${this.url}/folder/downloadall/${folderId}/${token}?includeFiles=${includeFiles}`;
    return this.http.get(url, { responseType: 'blob' });
  }

  downloadFilesZip(token: string, bulk: any[]) {
    const url = `${this.url}/folder/downloadfiles`;
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
    });
    const body = new URLSearchParams();
    body.set('json', JSON.stringify({ bulk }));
    body.set('authorization', token);

    return this.http.post(url, body.toString(), { 
      headers: headers,
      responseType: 'blob'
    });
  }


  getFolders(token: any, page = 1, perPage = 10, busqueda = "", filtros = [], type: any): Observable<any> {
    //const params = { authorization: token };
    const headers = this.createHeaders();

    let url;
    
    url = `${this.url}/folder/list?type=${type}&`;

    let json = null;
    if (busqueda === "") {
      json = {
        busqueda: "busqueda",
      };
    }

    json = JSON.stringify(json);

    const params = {
      json: encodeURIComponent(json),
      authorization: token
    };

    url = url + 'page=' + page + '&perpage=' + perPage;

    for (let filtro of filtros) {
      if (filtro && filtro.value) {
        url = url + "&" + filtro.key + "=" + filtro.value;

        if (filtro.type) {
          url = url + "&" + filtro.key_type + "=" + filtro.type;

        }
      }
    }

    return this.http.post<any>(
      url,
      this.buildSearchParams(params),
      { headers }
    );
  }

  getSubFolders(token: any,  page = 1, perPage = 10, busqueda = "", filtros = [], type: any, parent: any): Observable<any> {
    //const params = { authorization: token };
    const headers = this.createHeaders();

    let url;
    
    url = `${this.url}/folder/list?type=${type}&parent=${parent}&`;

    let json = null;
    if (busqueda === "") {
      json = {
        busqueda: "busqueda",
      };
    }

    json = JSON.stringify(json);

    const params = {
      json: encodeURIComponent(json),
      authorization: token
    };

    url = url + 'page=' + page + '&perpage=' + perPage;

    for (let filtro of filtros) {
      if (filtro && filtro.value) {
        url = url + "&" + filtro.key + "=" + filtro.value;

        if (filtro.type) {
          url = url + "&" + filtro.key_type + "=" + filtro.type;

        }
      }
    }

    return this.http.post<any>(
      url,
      this.buildSearchParams(params),
      { headers }
    );
  }

  getPOid(token: any, po: any): Observable<any> {
    const params = { authorization: token };
    const headers = this.createHeaders();

    return this.http.post<any>(
      `${this.url}/cita/getprocessinginfobyponumber/${po}?page=1`,
      this.buildSearchParams(params),
      { headers }
    );
  }

  orderdetailbypo(token: any, po: any): Observable<any> {
    const params = { authorization: token };
    const headers = this.createHeaders();

    return this.http.post<any>(
      `${this.url}/poinfo/orderdetailbypo/${po}?page=1`,
      this.buildSearchParams(params),
      { headers }
    );
  }

  

  getFolder(token: any, id: any): Observable<any> {
    const params = { authorization: token };
    const headers = this.createHeaders();
    const page = 1;

    return this.http.post<any>(
      `${this.url}/folder/detail/${id}?page=${page}`,
      this.buildSearchParams(params),
      { headers }
    );
  }

  update(token: any, task: any, id: any): Observable<any> {
    const json = JSON.stringify(task);
    const params = {
      json: encodeURIComponent(json),
      authorization: token
    };
    const headers = this.createHeaders();
    const page = 1;

    return this.http.post<any>(
      `${this.url}/folder/edit/${id}?page=${page}`,
      this.buildSearchParams(params),
      { headers }
    );
  }

  search(token: any, search: any = null, filter: any = null, order: any = null): Observable<any> {
    const params = {
      authorization: token,
      filter,
      order
    };
    const headers = this.createHeaders();
    const page = 1;
    const url = search == null ? `${this.url}/folder/search?page=${page}` : `${this.url}/folder/search/${search}?page=${page}`;

    return this.http.post<any>(
      url,
      this.buildSearchParams(params),
      { headers }
    );
  }

  deleteFolder(token: any, id: any): Observable<any> {
    const params = { authorization: token };
    const headers = this.createHeaders();
    const page = 1;

    return this.http.post<any>(
      `${this.url}/folder/remove/${id}?page=${page}`,
      this.buildSearchParams(params),
      { headers }
    );
  }

  private createHeaders(): HttpHeaders {
    return new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      'X-Requested-With': 'XMLHttpRequest'
    });
  }

  private buildSearchParams(params: any): any {
    return Object.keys(params)
      .map(key => `${key}=${params[key]}`)
      .join('&');
  }
}
