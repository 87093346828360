import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import PerfectScrollbar from 'perfect-scrollbar';
import { GuiGuard } from '../shared/guard/base-gui-guard';
import { BaseRouteGuard } from '../shared/guard/base-route-guard';
import { UserService } from '../services/user.service';
import {POService} from "../services/po.service";

import { GlobalNumberNotificationsService } from '../services/globalnumbernotifications.service';

declare const $: any;

//Metadata
export interface RouteInfo {
    path: string;
    title: string;
    type: string;
    icontype: string;
    collapse?: string;
    children?: ChildrenItems[];
}

export interface ChildrenItems {
    id: number;
    path: string;
    title: string;
    ab: string;
    type?: string;
}


export const MASTERROUTES: RouteInfo[] = [
    {
        path: '/master/home',
        title: 'Home',
        type: 'link',
        icontype: 'home'
    }, {
        path: '/master/companies',
        title: 'Master Companies',
        type: 'link',
        icontype: 'group'
    }
    , {
      path: '/master/logs',
      title: 'Access Logs',
      type: 'link',
      icontype: 'developer_mode'
  }
];


// Menu Items
export let ROUTES: RouteInfo[];


@Component({
    selector: 'app-sidebar-cmp',
    templateUrl: 'sidebar.component.html',
    styleUrls: ['./sidebar.component.css'],
    providers: [UserService, GuiGuard, BaseRouteGuard]
})

export class SidebarComponent implements OnInit {
    public menuItems: any[];
    public privilegio;
    public gobig;
    public identity;
    public logoCompany = '/assets/img/HASOFTWARElogo.png';
    public url_redirect= '';
    ps: any;
    public vendorStatus; 
    public permissionStatus = false;
    

    public token;
    public favorites: any[] = [];

    public favoriteItems: any[] = [];

    public unedaItems = [
      { id: 1, path: '/pages/uploaduneda', title: 'Configuration', ab: 'C' },
      { id: 2, path: '/tables/listunedainventory', title: 'My Inventory', ab: 'MI' }
    ]

    public ecommerceItems = [
      { id: 3, path: '/gobig/ebayaccounts', title: 'Ebay Accounts', ab: 'EA' },
      { id: 4, path: '/gobig/ebayexporter', title: 'Ebay Sync', ab: 'ES' },
      { id: 5, path: '/tables/listebayitems', title: 'Synced Items', ab: 'SI' },
      { id: 6, path: '/gobig/ebaylistings', title: 'Ebay Listings', ab: 'EL' },
      { id: 7, path: '/gobig/ebaylocations', title: 'Ebay Locations', ab: 'EL' },
      { id: 8, path: '/gobig/ebayorders', title: 'Ebay Orders', ab: 'EO' },
      { id: 9, path: '/gobig/easytemplates', title: 'Easy Templates', ab: 'ET' },
      { id: 10, path: '/gobig/returnpolicies', title: 'Return Policy', ab: 'RP' },
      { id: 11, path: '/gobig/paymentpolicies', title: 'Payment Policy', ab: 'PP' },
      { id: 12, path: '/gobig/fulfillmentpolicies', title: 'Fulfillment Policy', ab: 'FP' },
      { id: 13, path: '/gobig/viewinventory', title: 'View Inventory', ab: 'VI' },
      { id: 14, path: '/gobig/feedbackreminder', title: 'Feedback Reminder', ab: 'FR' },
      { id: 15, path: '/gobig/awaitingfeedbackitems', title: 'Awaiting Feedback Items', ab: 'AF' },
      { id: 16, path: '/gobig/feedbacksreceived', title: 'Feedbacks Received', ab: 'FR' },
      { id: 17, path: '/gobig/offers', title: 'Offers', ab: 'OF' }
    ]

    public bugReporting = [
      { id: 77, path: '/bug-reporting', title: 'Bug Reporting', ab: 'BG' }
    ]



    canCreate() {
      try {
        let st: any = this.guard.permissions("inventory-client"); 
        console.log('Valor retornado por permissions: ', st);
    
        if (st === undefined || st === null) {
          console.error('El valor de st es inválido: ', st);
          return;
        }
    
        // Verificar si 'st' es una cadena antes de intentar parsear
        if (typeof st === 'string') {
          try {
            // Solo parsear si la cadena no está vacía ni es 'undefined'
            if (st.trim() !== '' && st !== 'undefined') { 
              st = JSON.parse(st);
            } else {
              console.error('La cadena de st está vacía o no es JSON válido:', st);
              return;
            }
          } catch (jsonError) {
            console.error('Error al analizar JSON en permissions: ', jsonError);
            return;
          }
        }
    
        // Finalmente, convertir el resultado en un valor booleano
        this.permissionStatus = !!st;
    
      } catch (e) {
        console.log('Error en canCreate: ', e);
      }
    }
    


  
  
    constructor(

        private _route: ActivatedRoute,
        private _router: Router,
        private _userService: UserService,
        public guard: GuiGuard,
        private _poService: POService, 
        public _globalNumberNotificationService: GlobalNumberNotificationsService,
        public routeGuard: BaseRouteGuard) {
            this.identity = this._userService.getIdentity();
            this.token = this._userService.getToken();
           
            this.getFavorites();
            this.canCreate();
            
            
            if(this.identity.enableInventory  === 1){
                this.vendorStatus = true;
            }else if(this.identity.enableInventory === undefined){
              this.vendorStatus = false;
            }else{
              this.vendorStatus = false;
            }

          
            ROUTES = [
            {
              path: '/dashboard',
              title: 'Home',
              type: 'link',
              icontype: 'dashboard'
            },
            {
              path: '/agenda',
              title: 'Calendar',
              type: 'link',
              icontype: 'date_range',
            },
            (this.permissionStatus && this.vendorStatus)
            ? 
            {
              path: '/inventory-client',
              title: 'inventory-client',
              type: 'link',
              icontype: 'sort',
            }: null,
            {
              path: 'clients',
              title: 'Clients',
              type: 'sub',
              icontype: 'group',
              collapse: 'clients',
              children: [
                { id: 18, path: '/tables/listcompanies', title: 'Companies', ab: 'Co' },
                { id: 19, path: '/vendors', title: 'Vendors', ab: 'V' },
                { id: 20, path: '/customers', title: 'Customers', ab: 'Cu' },
                { id: 21, path: '/mycontactforms', title: 'My Leads', ab: 'ML' },
                { id: 22, path: '/tables/listcontactform', title: 'Leads', ab: 'L' },
                { id: 23, path: '/tables/listlogs', title: 'Access Logs', ab: 'AL' },
              ],
            },
            this.identity.role === 'VENDOR' || this.identity.role === 'CUSTOMER'
              ? {
                  path: '/tables/listfiles',
                  title: 'Reports',
                  type: 'link',
                  icontype: 'folder',
                }
              :
            {
              path: 'files',
              title: 'Files',
              type: 'sub',
              icontype: 'folder',
              collapse: 'files',
              children: [
                { id: 24, path: '/centralizedfiles', title: 'Centralized Files', ab: 'CF' },
                { id: 25, path: '/tables/listfiles', title: 'CV Files', ab: 'CV' },
                { id: 26, path: '/reports', title: 'Reports', ab: 'R' },
                { id: 27, path: '/tables/listcompanyfiles', title: 'Company Files', ab: 'F' },
                { id: 28, path: '/questionnaire', title: 'Questionnaire', ab: 'Q' },
              ],
            },
            {
              path: 'tools',
              title: 'Tools',
              type: 'sub',
              icontype: 'build',
              collapse: 'tools',
              children: [
                { id: 29, path: '/anonymusreporting', title: 'Anonymous Reporting', ab: 'AR' },
                { id: 30, path: '/backgroundprocess', title: 'Background Processes', ab: 'BP' },
                { id: 31, path: '/hdchecker', title: 'HDD Tools', ab: 'HT' },
                { id: 32, path: '/insidelocation', title: 'Inside a location', ab: 'IL' },
                { id: 33, path: '/psa', title: 'PSA', ab: 'P' },
                { id: 34, path: '/reminders', title: 'Reminders', ab: 'R' },
                { id: 35, path: '/testing-record', title: 'Testing Records', ab: 'TR' },
                { id: 36, path: '/tickets', title: 'Tickets', ab: 'T' },
                { id: 37, path: '/holidays', title: 'Time off Request', ab: 'TO' },
                { id: 38, path: '/sign-sheet', title: 'Visitors', ab: 'V' },
                { id: 39, path: '/logsSystem', title: 'Central Logs', ab: 'CL' },
                { id: 40, path: '/weightbalance', title: 'Weight Balance', ab: 'WB' },
                { id: 41, path: '/forms-reports', title: 'Forms', ab: 'FR' },
                { id: 42, path: '/securaze', title: 'Securaze', ab: 'SR' },
                { id: 43, path: '/workflow-process', title: 'Workflow Process', ab: 'WP'},
              ],
            },
            {
              path: 'sell',
              title: 'Sell',
              type: 'sub',
              icontype: 'payments',
              collapse: 'sell',
              children: [
                { id: 44, path: '/salesproposal', title: 'Sales Proposal', ab: 'SP' },
                { id: 45, path: '/sales-orders', title: 'Sales Orders', ab: 'SO' },
                { id: 46, path: '/invoices', title: 'Invoices', ab: 'I' },
              ],
            },
            {
              path: 'buy',
              title: 'Buy',
              type: 'sub',
              icontype: 'work',
              collapse: 'buy',
              children: [
                { id: 47, path: '/purchaseproposal', title: 'Purchase Proposal', ab: 'PP' },
                { id: 48, path: '/purchase-order', title: 'Purchase Orders', ab: 'PO' },
                {
                  id: 49, 
                  path: '/processing-instructions',
                  title: 'Processing Instructions',
                  ab: 'PI',
                },
                { id: 50, path: '/tables/listpagos', title: 'Payments', ab: 'PY' },
              ],
            },
            {
              path: 'inventory',
              title: 'Inventory',
              type: 'sub',
              icontype: 'sort',
              collapse: 'inventory',
              children: [
                { id: 51, path: '/batch-container-inventory', title: 'Batch Container Inventory', ab: 'BCI' },
                { id: 52, path: '/container-inventory', title: 'Container Inventory', ab: 'CI' },
                { id: 53, path: '/inventoryhistory', title: 'Inventory History', ab: 'IH' },
                { id: 54, path: '/inventory', title: 'Product Inventory', ab: 'I' },
                { id: 55, path: '/manufacturer', title: 'Manufacturer', ab: 'M' },
                { id: 56, path: '/priceHistory', title: 'Price History', ab: 'PH' },
                // { path: '/product-inventory', title: 'Product Inventory', ab: 'PI' },
                { id: 57, path: '/scrap-inventory', title: 'Scrap Inventory', ab: 'SI' },
                { id: 58, path: '/write-downs', title: 'Write Downs', ab: 'WD' },
              ],
            },{
              path: 'inventoryReports',
              title: 'Live Reporting',
              type: 'sub',
              icontype: 'analytics',
              collapse: 'analytics',
              children: [
                { id: 59, path: '/inventoryReports', title: 'Inventory Reports', ab: 'IR' },
                { id: 76, path: '/liveReporting/price-History', title: 'Price History', ab: 'PH' },
                { id: 60, path: '/inventoryReportExtraMissing', title: 'Inventory Extra/Missing', ab: 'EM' },
                { id: 61, path: '/inventoryReportExpenses', title: 'Expenses Reports', ab: 'E' },
                { id: 62, path: '/inventoryReportsComparative', title: 'Comparison Reports', ab: 'CR' },
                { id: 63, path: '/inventoryReportsDownloadManager', title: 'Download Manager', ab: 'DM' }
              ],
            },
            {
              path: 'shipments',
              title: 'Order Processing',
              type: 'sub',
              icontype: 'local_shipping',
              collapse: 'Shipments',
              children: [
                { id: 64, path: '/carriers', title: 'Carriers', ab: 'SP' },
                { id: 65, path: '/shipments', title: 'Shipments', ab: 'SP' },
                { id: 66, path: '/returns', title: 'Returns', ab: 'SP' }
              ],
            },
            {
              path: 'accounting',
              title: 'Accounting',
              type: 'sub',
              icontype: 'account_balance',
              collapse: 'accounting',
              children: [
                { id: 67, path: '/quickbooksconnect', title: 'QuickBooks Connect', ab: 'QB' },
                { id: 68, path: '/sync-quickbooks', title: 'Sync with QuickBooks', ab: 'SQ' },
              ],
            },
            {
              path: 'settings',
              title: 'Settings',
              type: 'sub',
              icontype: 'settings',
              collapse: 'settings',
              children: [
                { id: 69, path: '/tables/listpersonal', title: 'Representatives', ab: 'R' },
                { id: 70, path: '/trucks', title: 'Trucks', ab: 'T' },
                { id: 71, path: '/permissions', title: 'Permissions', ab: 'P' },
              ],
            },
            {
              path: '/statistics',
              title: 'Statistics',
              type: 'link',
              icontype: 'assessment',
            },
            {
              path: 'setup',
              title: 'Setup',
              type: 'link',
              icontype: 'build',
            },
            {
              path: 'profile',
              title: 'Profile',
              type: 'sub',
              icontype: 'person',
              collapse: 'profile',
              children: [
                { id: 72, path: '/files-permissions-groups', title: 'File Permissions Groups', ab: 'FG' },
                { id: 73, path: '/pages/profile/1', title: 'My Profile', ab: 'MP' },
                { id: 74, path: '/tagging-groups', title: 'Tagging Groups', ab: 'TG' },
                { id: 75, path: '/visibilitytables', title: 'Visibility Tables', ab: 'VT' },
              ],
            },
          ];

            ROUTES[ROUTES.length -1].children.push({ id: 75, path: `/pages/userchangepassword/${this.identity.sub}`, title: 'Change Password', ab: 'CP' });




            if(typeof this.identity.shemale === 'undefined'){
                localStorage.clear();
                this._router.navigate(['/pages/lock']);
            
            }
           
            if (this.identity.shemale !== null && this.identity.shemale.includes('37')) {
                this.gobig = true;
            } else {
                this.gobig = false;
            }

            this.logoCompany = this.identity.companyLogo === 'nologo' ?
            '/assets/img/HASOFTWARElogo.png' : 'https://itaderp.com/backend/resources/' + this.identity.companyLogo;
            if (typeof this.logoCompany === 'undefined') {
              this.logoCompany = '/assets/img/HASOFTWARElogo.png';
            }
            this.url_redirect = this.identity.logoRedirect ? this.identity.logoRedirect : '/';
            //console.log("sidebar identity",this.identity);
        }


    isMobileMenu() {
        if ($(window).width() > 991) {
            return false;
        }
        return true;
    };

    logout() {
        localStorage.clear();
        this._router.navigate(['/']);
        ROUTES[ROUTES.length -1].children.pop();
        this._globalNumberNotificationService.numberNotifications = 0;
    }

    active(path) {
        if (this.identity.role === 'MASTERADMIN') {
            return true;
        }

        if (path.includes('profile') || path.includes('editprofile') || path.includes('userchangepassword')) {
          return true;
        }
        
        return this.routeGuard.checkPermissions(path);
    }

    activeSection(title) {

        if (this.identity.role === 'MASTERADMIN') {
            return true;
        }
       

        switch (title) {
            case 'Home':
                return true;
            case 'Profile':
                return true;
            case 'Calendar':
          
                return this.guard.permissions('agenda');
            case 'Inventory-client':
                  return this.guard.permissions('/inventory-client');    
            case 'Statistics':
                return this.guard.permissions('statistics');
            case 'Clients':
                return (this.guard.permissions('listcompanies')
                     || this.guard.permissions('vendors')
                     || this.guard.permissions('customers')
                     || this.guard.permissions('mycontactforms')
                     || this.guard.permissions('listcontactform')
                     || this.guard.permissions('listlogs'));
            case 'Files':
                return (this.guard.permissions('listfiles')
                     || this.guard.permissions('centralizedfiles')
                     || this.guard.permissions('reports')
                     || this.guard.permissions('listcompanyfiles')
                     || this.guard.permissions('questionnaire'));
            case 'Tools':
                return (this.guard.permissions('hdchecker')
                     || this.guard.permissions('backgroundprocess')
                     || this.guard.permissions('testing-record')
                     || this.guard.permissions('holidays')
                     || this.guard.permissions('sign-sheet')
                     || this.guard.permissions('tickets')
                     || this.guard.permissions('psa')
                     || this.guard.permissions('insidelocation')
                     || this.guard.permissions('logsSystem')
                     || this.guard.permissions('weightbalance')
                     || this.guard.permissions('forms-reports')
                     || this.guard.permissions('anonymusreporting')
                     || this.guard.permissions('reminders'));
            case 'Settings':
                return (this.guard.permissions('listpersonal')
                     || this.guard.permissions('trucks')
                     || this.guard.permissions('permissions'));
            case 'Buy':
                return (this.guard.permissions('purchase-order')
                     || this.guard.permissions('processing-instructions')
                     || this.guard.permissions('listpagos')
                     || this.guard.permissions('proposal'));
            case 'Inventory':
                return (
                    this.guard.permissions('/inventory')
                    || this.guard.permissions('/batch-container-inventory')
                     || this.guard.permissions('/inventoryhistory')
                     || this.guard.permissions('priceHistory')
                     || this.guard.permissions('manufacturer')
                     || this.guard.permissions('master-types')
                     || this.guard.permissions('scrap-inventory')
                     || this.guard.permissions('write-downs')
                     || this.guard.permissions('container-inventory')
                     || this.guard.permissions('product-inventory')
                    );
             case 'Live Reporting':
                 return (this.guard.permissions('/inventoryReports')
                     || this.guard.permissions('/liveReporting/price-History')
                     || this.guard.permissions('/inventoryReportExtraMissing')
                     || this.guard.permissions('/inventoryReportExpenses')
                     || this.guard.permissions('/inventoryReportsDownloadManager')
                     || this.guard.permissions('/inventoryReportsComparative')
                   );
            case 'Accounting':
                return (this.guard.permissions('quickbooksconnect')
                    || this.guard.permissions('sync-quickbooks'));
            case 'Setup':
                return (this.guard.permissions('locations')
                    || this.guard.permissions('sale-terms')
                    || this.guard.permissions('purchase-terms')
                    || this.guard.permissions('notifications-perms')
                    || this.guard.permissions('system-setup')
                    || this.guard.permissions('master-items')
                    || this.guard.permissions('productCategory')
                    || this.guard.permissions('prouct-conditions')
                    || this.guard.permissions('taxauthorities')
                    || this.guard.permissions('visitorlink')
                    || this.guard.permissions('erasuremethods')
                    || this.guard.permissions('configpto')
                    || this.guard.permissions('containerstype')
                    );
            case 'Sell':
                return (
                    this.guard.permissions('salesproposal')
                    || this.guard.permissions('sales-orders')
                    || this.guard.permissions('invoices')
                  );
            case 'Order Processing':
                return (
                    this.guard.permissions('returns') ||
                    this.guard.permissions('shipments')
                    );
            default:
                return true;
        }
    }


    ngOnInit() {
        if (this.identity.role === 'MASTERADMIN') {
            this.menuItems = MASTERROUTES.filter(menuItem => menuItem);
        } else {
            this.menuItems = ROUTES.filter(menuItem => menuItem);

            if(this.identity.role === 'VENDOR'){

              if(this.identity.companyId !== 1001){
                this.menuItems = this.menuItems.filter(function(item) {
                  return item.path !== 'inventoryReports';
                });
              }

              this.menuItems = this.menuItems.map(item => {
                if (item.path === "/dashboard") {
                  return {
                    "path": "/homeclient",
                    "title": "Home",
                    "type": "link",
                    "icontype": "dashboard",
                    "visible": true
                  };
                }
                return item;
              });
            }
            
            this.menuItems.forEach( element => {
               // console.log("element => " , element);
               // console.log("this.activeSection(element.title) => ", this.activeSection(element.title));
                element.visible = this.activeSection(element.title);
                if(element.children){
                    element.children.forEach(elementInterno => {
                        elementInterno.visible = this.active(elementInterno.path); 
                    });
                }
            })
        }


 
        this.privilegio = JSON.parse(localStorage.getItem('privilegio'));
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            const elemSidebar = <HTMLElement>document.querySelector('.sidebar .sidebar-wrapper');
            this.ps = new PerfectScrollbar(elemSidebar);
        }

       
    }
    updatePS(): void  {
        if (window.matchMedia(`(min-width: 960px)`).matches && !this.isMac()) {
            this.ps.update();
        }
    }
    isMac(): boolean {
        let bool = false;
        if (navigator.platform.toUpperCase().indexOf('MAC') >= 0 || navigator.platform.toUpperCase().indexOf('IPAD') >= 0) {
            bool = true;
        }
        return bool;
    }
    expandOrCollapseMenu(id){
        let parent = document.getElementById(id+ "-p");
        let child = document.getElementById(id);
        parent.ariaExpanded = parent.ariaExpanded === "true" ? "false" : "true";
        child.style.height = child.style.height === "0px" || child.style.height === ""  ? "100%" : "0";
    }

    addToFavorites(id){
      this._userService.addToFavorites(this.token, id).subscribe(
        (response) => {
          console.log(response);
          if (response.status == "success") {

            this.getFavorites();
            
          } else {
            
          }
        },
        (error) => {
          console.log(<any>error);
        }
      );
    }

    removeFromFavorites(id){
      this._userService.removeFromFavorites(this.token, id).subscribe(
        (response) => {
          console.log(response);
          if (response.status == "success") {

            this.getFavorites();
            
          } else {
            
          }
        },
        (error) => {
          console.log(<any>error);
        }
      );
    }

    getFavorites(){
      this._userService.getFavorites(this.token).subscribe(
        (response) => {

          if (response.status == "success") {
            console.log(ROUTES);

            let idToFind;
            let childrenFoundFlag = false;

            this.favoriteItems = [];

            if(response.data !== null){

                for (idToFind of response.data) {

                childrenFoundFlag = false;
                
                // Busca el elemento con el id proporcionado
                ROUTES.forEach(item => {

                  if(item !== null){

                    if (item.children) {
                      // Filtra los children que tienen el id proporcionado
                      const childrenWithId = item.children.filter(child => child.id === idToFind);
                      // Agrega los children encontrados al array
                      if (childrenWithId.length > 0) {
                        childrenFoundFlag = true; // Se encontraron children
                        this.favoriteItems.push(...childrenWithId);
                      }
                    }
                    
                  }
                });

                if (!childrenFoundFlag) {            

                  const itemFound = this.unedaItems.find(item => item.id === idToFind);

                  if (itemFound) {
                    this.favoriteItems.push(itemFound);
                  } else {
                    
                    const itemFound = this.ecommerceItems.find(item => item.id === idToFind);

                    if (itemFound) {
                      this.favoriteItems.push(itemFound);
                    } else {

                      const itemFound = this.bugReporting.find(item => item.id === idToFind);

                      if (itemFound) {
                        this.favoriteItems.push(itemFound);
                      }
                    }

                  }
                  
                }

              }

              this.favorites = response.data;

            } else this.favorites = [];
          }
        },
        (error) => {
          console.log(<any>error);
        }
      );
    }

}