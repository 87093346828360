export const environment = {
  production: false,
  url: 'https://www.itaderp.com/backend/crm/web',
  firebase: {
    apiKey: 'AIzaSyCetwpOQQmucSHAoUa1FFM9wqJBjmB6xJQ',
    authDomain: 'greentek-245a7.firebaseapp.com',
    databaseURL: 'https://greentek-245a7.firebaseio.com',
    projectId: 'greentek-245a7',
    storageBucket: 'greentek-245a7.appspot.com',
    messagingSenderId: '262604010580'
  }
};