declare const $: any;

export class Methods {
  constructor() {}


 parseLocalDate(dateString) {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day);
}

  getFecha(fecha: Date, format = "yyyy-MM-dd") {
    let dia;
    dia = fecha.getDate();
    let day;
    if (dia < 10) {
      day = "0" + dia;
    } else {
      day = dia;
    }
    let mes;
    mes = fecha.getMonth() + 1;
    let month;
    if (mes < 10) {
      month = "0" + mes;
    } else {
      month = mes;
    }
    const anio = fecha.getFullYear();

    const hour = fecha.getTime();

    switch (format) {
      case "MM-dd-yyyy":
        return month + "-" + day + "-" + anio;
      case "yyyy-MM-dd":
        return anio + "-" + month + "-" + day;
      case "MM/dd/yyyy":
        return month + "/" + day + "/" + anio;
      case "dd/MM/yyyy":
        return day + "/" + month + "/" + anio;
      case "dd-MM-yyyy":
        return day + "-" + month + "-" + anio;
      case "MM/dd/yyyy h:mm:ss":
        return (
          month +
          "/" +
          day +
          "/" +
          anio +
          " " +
          fecha.getHours() +
          ":" +
          fecha.getMinutes() +
          ":" +
          fecha.getSeconds()
        );
      default:
        return anio + "-" + month + "-" + day;
    }
  }

  capitalize(word: string) {
    if (!word) {
      return "";
    }
    word = word.toLowerCase();
    return word.replace(/\b\w/g, (l) => l.toUpperCase());
  }

  pipFecha(fecha: string, formato: string = "MM/dd/yyyy", type = "estandar") {
    let sgms;

    switch (type) {
      case "estandar":
        sgms = fecha.split("-");

        switch (formato) {
          case "MM/dd/yyyy":
            return sgms[1] + "/" + sgms[2] + "/" + sgms[0];
        }
        break;
      case "EEUU":
        sgms = fecha.split(" ");
        return sgms[0];
      default:
        break;
    }
  }

  fixed(monto: number, fix: number = 1) {
    if (monto != null) {
      monto = Number(monto.toFixed(fix));
    }

    if (monto < 0) {
      monto = monto * -1;
    }
    return monto;
  }

  getHora(hora, formato: string = "24h") {
    let sgms;
    sgms = hora.split(":");
    if (sgms[0].length === 1) {
      sgms[0] = "0" + sgms[0];
    }

    if (sgms[1].length === 1) {
      sgms[1] = "0" + sgms[1];
    }

    switch (formato) {
      case "24h":
        return sgms[0] + ":" + sgms[1];
      case "12A":
        let ap;
        ap = "AM";
        if (Number(sgms[0]) === 12) {
          ap = "PM";
        } else if (Number(sgms[0]) > 12) {
          sgms[0] = String(Number(sgms[0]) - 12);
          ap = "PM";
        }
        return sgms[0] + ":" + sgms[1] + ap;
      default:
        return sgms[0] + ":" + sgms[1];
    }
  }

  colopt(columns: Array<string>, colVis: Array<boolean> = []) {
    const options = [];
    let idx = 0;

    for (const col of columns) {
      const option = {
        colName: col,
        position: idx,
        visibility: colVis[idx] !== undefined ? colVis[idx] : true,
        origin: idx,
      };
      options.push(option);
      idx++;
    }

    return options;
  }

  initSimpleTable(id_table: string) {
    $(id_table).DataTable().clear();
    $(id_table).DataTable().destroy();
    setTimeout(function () {
      $(id_table).DataTable({
        bInfo: false,
        ordering: false,
        searching: false,
        paging: false,
        dom: "Bflrtip",
        buttons: [
          /*{
              extend: 'copy',
              exportOptions: {
                columns: ':visible'
              }
            },*/ /*
            {
              extend: 'csv',
              exportOptions: {
                columns: ':visible'
              }
            },*/
          {
            extend: "excel",
            text: `<i class='fa fa-file-excel-o'></i>`,
            titleAttr: "Excel",
            className: "btn btn-sm btn-outline-success",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdf",
            text: `<i class='fa fa-file-pdf-o'></i>`,
            titleAttr: "PDF",
            className: "btn btn-sm btn-outline-danger",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: `<i class='fa fa-print' aria-hidden='true'></i>`,
            titleAttr: "primary",
            className: "btn btn-sm btn-outline-primary",
            exportOptions: {
              columns: ":visible",
            },
          },
          // {
          //   extend: 'colvis',
          //   text: '<i class='fa fa-columns' aria-hidden='true'></i>',
          //   className: 'btn btn-sm btn-outline-info',
          //   titleAttr: 'Columnas Visibles',
          // },
        ],
        pagingType: "full_numbers",
        lengthMenu: [
          [5, 10, 25, 50, 100, 200, 500, 1000, -1],
          [5, 10, 25, 50, 100, 200, 500, 1000, "All"],
        ],
        responsive: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search records",
        },
      });
    }, 1);
  }

  initTable(id_table: string, sort = null, order = "desc") {
    // desc o asc
    $(id_table).DataTable().clear();
    $(id_table).DataTable().destroy();
    setTimeout(function () {
      $(id_table).DataTable({
        order: [[sort !== null ? sort : 0, order]],
        dom: "Bflrtip",
        buttons: [
          /*{
              extend: 'copy',
              exportOptions: {
                columns: ':visible'
              }
            },*/ /*
            {
              extend: 'csv',
              exportOptions: {
                columns: ':visible'
              }
            },*/
          {
            extend: "excel",
            text: `<i class='fa fa-file-excel-o'></i>`,
            titleAttr: "Excel",
            className: "btn btn-sm btn-outline-success",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "pdf",
            text: `<i class='fa fa-file-pdf-o'></i>`,
            titleAttr: "PDF",
            className: "btn btn-sm btn-outline-danger",
            exportOptions: {
              columns: ":visible",
            },
          },
          {
            extend: "print",
            text: `<i class='fa fa-print' aria-hidden='true'></i>`,
            titleAttr: "primary",
            className: "btn btn-sm btn-outline-primary",
            exportOptions: {
              columns: ":visible",
            },
          },
        ],
        pagingType: "full_numbers",
        lengthMenu: [
          [5, 10, 25, 50, 100, 200, 500, 1000, -1],
          [5, 10, 25, 50, 100, 200, 500, 1000, "All"],
        ],
        responsive: true,
        language: {
          search: "_INPUT_",
          searchPlaceholder: "Search records",
        },
      });
    }, 1);
  }

  onlyNumber(e) {
  
    let charCode = e.keyCode;
     return charCode > 31 && (charCode < 48 || charCode > 57)
    ? false
    : charCode === 13
    ? false
    : true
  }

  onlyNumberAndEnter(e) {
  
    let charCode = e.keyCode;
    if(charCode == 13) return true;

    return charCode > 31 && (charCode < 48 || charCode > 57)
    ? false
    : charCode === 13
    ? false
    : true
  }

  onlyLeters(event) {
    if (
      (event.keyCode != 32 && event.keyCode < 65) ||
      (event.keyCode > 90 && event.keyCode < 97) ||
      event.keyCode > 122
    ) {
      return false;
    }
    return true;
  }

  numTxt(event) {
    return this.onlyLeters(event) || this.onlyNumber(event);
  }

  notifyError(
    mensaje: string,
    status = 500,
    from: any = "bottom",
    align: any = "right"
  ) {
    const type = [
      "",
      "info",
      "success",
      "warning",
      "danger",
      "rose",
      "primary",
    ];
    const color = Math.floor(Math.random() * 6 + 1);

    if (mensaje === "OK" && status === 500) {
      mensaje = "Error 500: Contact support";
    }

    $.notify(
      {
        icon: "notifications",
        message: mensaje,
      },
      {
        type: "danger",
        timer: 500,
        placement: {
          from: from,
          align: align,
        },
        template: `
        <div data-notify='container' class='col-xs-11 col-sm-3 alert alert-{0} alert-with-icon' role='alert'>
          <button mat-raised-button type='button' aria-hidden='true' class='close' data-notify='dismiss'>
            <i class='material-icons'>close</i>
          </button>
          <i class='material-icons' data-notify='icon'>notifications</i>
          <span data-notify='title'>{1}</span>
          <span data-notify='message'>{2}</span>
          <div class='progress' data-notify='progressbar'>
            <div class='progress-bar progress-bar-{0}' role='progressbar'
              aria-valuenow='0' aria-valuemin='0' aria-valuemax='100' style='width: 0%;'>
            </div>
          </div>
          <a href='{3}' target='{4}' data-notify='url'></a>
        </div>
        `,
      }
    );
  }

  notifySuccess(mensaje: string, from: any = "bottom", align: any = "right") {
    const type = [
      "",
      "info",
      "success",
      "warning",
      "danger",
      "rose",
      "primary",
    ];

    const color = Math.floor(Math.random() * 6 + 1);

    $.notify(
      {
        icon: "notifications",
        message: mensaje,
      },
      {
        type: "success",
        timer: 500,
        placement: {
          from: from,
          align: align,
        },
        template: `
        <div data-notify='container' class='col-xs-11 col-sm-3 alert alert-{0} alert-with-icon' role='alert'>
          <button mat-raised-button type='button' aria-hidden='true' class='close' data-notify='dismiss'>
            <i class='material-icons'>close</i>
          </button>
          <i class='material-icons' data-notify='icon'>notifications</i>
          <span data-notify='title'>{1}</span>
          <span data-notify='message'>{2}</span>
          <div class='progress' data-notify='progressbar'>
            <div class='progress-bar progress-bar-{0}' role='progressbar' aria-valuenow='0' aria-valuemin='0' aria-valuemax='100'
              style='width: 0%;'>
            </div>
          </div>
          <a href='{3}' target='{4}' data-notify='url'></a>
        </div>
        `,
      }
    );
  }

  capitalizar(texto) {
    const palabras = [];

    for (const palabra of texto.split(" ")) {
      palabras.push(palabra[0].toUpperCase() + palabra.substring(1));
    }
    return palabras.join(" ");
  }

  booleano(dato) {
    return dato === 1 || dato === "1" || dato === true;
  }

  emailValid(email) {
    const regExpEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(
      email
    );
    return regExpEmail;
  }
}
