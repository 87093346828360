import { Component, OnInit, Input } from '@angular/core';
import { Icon }from '../../models/card-icon'
@Component({
  selector: 'app-card-icon-title',
  templateUrl: './card-icon-title.component.html',
  styleUrls: ['./card-icon-title.component.css']
})
export class CardIconTitleComponent implements OnInit {
  @Input() 'icon': Icon = {
    title: '',
    icon: ''
  };
  constructor() { }

  ngOnInit() {  
  }

}
