<h1 mat-dialog-title>Searching Results:</h1>
<div mat-dialog-content style="position: relative;">
  
  <div class="row"  id="hdporesults">
                        <div class="col-md-12" style="    line-height: 30px;">
                        
                        
                          <div class="row">
                               <div class="col-md-2 bg-light">
                                  Manufacturer
                              </div> 
                              <div class="col-md-2 bg-light">
                                Model
                            </div>  
                             <div class="col-md-2 bg-light">
                                Serial Number
                            </div> 
                             <div class="col-md-2 bg-light">
                                Results
                            </div>  
                             <div class="col-md-2 bg-light">
                                PO
                            </div>
                            <div class="col-md-2 bg-light">
                                Actions
                            </div>  
                          </div>
                          <div class="row" *ngFor="let row of data" style="    border-top: 1px solid #eee;">
                              
                            <div class="col-md-2">
                              {{row.Manufacturer}}
                            </div>
                             <div class="col-md-2">
                                {{row.Model}}
                            </div>
                             <div class="col-md-2">
                                {{row.SerialNumber}}
                            </div>
                             <div class="col-md-2 bg-success text-white" [ngClass]="{
    'bg-success':row.ErasureResults === 'PASSED' || row.ErasureResults === 'Secure Auto Passed!',
    'bg-danger':row.ErasureResults === 'FAILED',
    'bg-warning':row.ErasureResults === 'PARTIAL'
  }">
                                {{row.ErasureResults}}
                            </div>
                             <div class="col-md-2" style="font-weight: bold;">
                                {{row.PoNumber}}
                            </div>
                            <div *ngIf="row.SerialNumber != null" class="col-md-2">
                               
                                <button class="btn btn-success" mat-button [mat-dialog-close]="row" cdkFocusInitial>Ok, Modify SN</button>
                            </div>
                          </div>
                       
                        


                        
                      </div>


                    </div>
</div>
<div mat-dialog-actions>
 <button  class="btn btn-danger" mat-button [mat-dialog-close]="" cdkFocusInitial><i class="material-icons">
close
</i> Close</button>
  
</div>