import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'Showstage'
})
export class Showstage implements PipeTransform {
   transform(val: number): any {
       
    let valorisPI:any = '';
              if(val == 0)
                valorisPI = '2nd';
              else if(val == 1)
                valorisPI = '1st';
              else if(val == 3)
                valorisPI = '3rd';
              else if(val == 4)
                valorisPI = '4th';


    return valorisPI;
   }
}