import { Component, OnInit, Renderer2, ViewChild, ElementRef, Directive } from '@angular/core';
import { ROUTES } from '../.././sidebar/sidebar.component';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';


import {webSocket, WebSocketSubject} from 'rxjs/webSocket';
import { UserService } from '../../services/user.service';
import { NotificacionesService } from '../../services/notificaciones.service';
import { FolderService } from '../../services/folder.service';
import { Methods } from '../../shared/methods';

import { GlobalNumberNotificationsService } from '../../services/globalnumbernotifications.service';


const misc: any = {
    navbar_menu_visible: 0,
    active_collapse: true,
    disabled_collapse_init: 0,
};

declare var $: any;
@Component({
    selector: 'app-navbar-cmp',
    templateUrl: 'navbar.component.html',
    providers: [UserService, NotificacionesService]
})

export class NavbarComponent implements OnInit {
    public typesearch = 'pi';
    public mtds: Methods = new Methods();
    private listTitles: any[];
    location: Location;
    public identity: any;
    public token;
    mobile_menu_visible: any = 0;
    private nativeElement: Node;
    private toggleButton: any;
    private sidebarVisible: boolean;
    private _router: Subscription;
    public privilegio;
    public numberNotifications: any = 0;
    public notificationsData:any[];
    public searching = false;
    myWebSocket: WebSocketSubject<any> = webSocket('wss://www.greenteksolutions.com/myws');

    public searchpo: string = '';

    @ViewChild('app-navbar-cmp', {static: false}) button: any;

    constructor(

private _notificacionesService: NotificacionesService,
     private _fService: FolderService,
     location: Location,
   
     private element: ElementRef,
     private router: Router,
     private _userService: UserService,
     public _globalNumberNotificationService: GlobalNumberNotificationsService,


     private renderer: Renderer2) {
        this.location = location;
        this.nativeElement = element.nativeElement;
        this.sidebarVisible = false;
          this.identity = this._userService.getIdentity();
        this.token = this._userService.getToken();
    }
    minimizeSidebar(){
      const body = document.getElementsByTagName('body')[0];

      if (misc.sidebar_mini_active === true) {
          body.classList.remove('sidebar-mini');
          misc.sidebar_mini_active = false;

          // Store the value in local storage
            localStorage.setItem('sidebar_mini_active', 'false');

      } else {
          setTimeout(function() {
              body.classList.add('sidebar-mini');

              misc.sidebar_mini_active = true;
              // Store the value in local storage
                localStorage.setItem('sidebar_mini_active', 'true');
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }
    hideSidebar(){
      const body = document.getElementsByTagName('body')[0];
      const sidebar = document.getElementsByClassName('sidebar')[0];

      if (misc.hide_sidebar_active === true) {
          setTimeout(function() {
              body.classList.remove('hide-sidebar');
              misc.hide_sidebar_active = false;
          }, 300);
          setTimeout(function () {
              sidebar.classList.remove('animation');
          }, 600);
          sidebar.classList.add('animation');

      } else {
          setTimeout(function() {
            body.classList.add('hide-sidebar');
              // $('.sidebar').addClass('animation');
              misc.hide_sidebar_active = true;
          }, 300);
      }

      // we simulate the window Resize so the charts will get updated in realtime.
      const simulateWindowResize = setInterval(function() {
          window.dispatchEvent(new Event('resize'));
      }, 180);

      // we stop the simulation of Window Resize after the animations are completed
      setTimeout(function() {
          clearInterval(simulateWindowResize);
      }, 1000);
    }

    searchingPO() {
        // Limpiar this.searchpo para que contenga solo números
        this.searchpo = this.searchpo.replace(/\D/g, '');
        switch (this.typesearch) {
            case 'pi':
                  if (this.searchpo.length >= 4) {
                          this.searching = true;
                          this._fService.getPOid(this.token, this.searchpo).subscribe(
                              r => {
                                  //console.log("response getPOid => ", r);
                                  if (r.code === 400 && r.msg === 'Authorization not valid') {
                                    this.mtds.notifyError('Your session has expired');
                                    localStorage.clear();
                                    this.router.navigate(['/pages/lock']);
                                  } else if (r.status === 'success') {
                                      window.location.href = '/processing-instructions/' + r.data.id;
                                      // this.router.navigate(['/processing-instructions/' + r.data.id]);
                                  } else if (r.code === 404) {
                                      window.location.href = '/processing-instructions/';
                                      // this.router.navigate(['/processing-instructions/' + 12345]);
                                  } else {this.mtds.notifyError(r.msg, r.code);}
                                  this.searching = false;
                                }, error => {
                                  this.searching = false;
                                  this.mtds.notifyError(error.statusText, error.status);
                                }
                          );
                         // this.router.navigate(['/processing-instructions/' + this.searchpo]);
                      }
                break;
            case 'po':
                if (this.searchpo.length >= 4) {
                    this.searching = true;
                    this._fService.orderdetailbypo(this.token, this.searchpo).subscribe(
                        r => {
                            //console.log("response getPOid => ", r);
                            if (r.code === 400 && r.msg === 'Authorization not valid') {
                              this.mtds.notifyError('Your session has expired');
                              localStorage.clear();
                              this.router.navigate(['/pages/lock']);
                            } else if (r.status === 'success') {
                                window.location.href = '/purchase-order/' + r.data.id;
                                // this.router.navigate(['/processing-instructions/' + r.data.id]);
                            } else if (r.code === 404) {
                                window.location.href = '/purchase-order/';
                                // this.router.navigate(['/processing-instructions/' + 12345]);
                            } else {this.mtds.notifyError(r.msg, r.code);}
                            this.searching = false;
                          }, error => {
                            this.searching = false;
                            this.mtds.notifyError(error.statusText, error.status);
                          }
                    );
                   // this.router.navigate(['/processing-instructions/' + this.searchpo]);
                }
                break;
            case 'cid':
                window.location.href = '/container-inventory/' + this.searchpo;
                break;
            case 'ticket':
                window.location.href = '/tickets/' + this.searchpo;
                break;
            default:
                break;
        }

    }

  getPlaceholderText(): string {
    return this.typesearch === 'ticket' ? 'Ticket' : this.typesearch.toUpperCase();
  }

 marknotification(notification) {

        this._notificacionesService.marked('markread', {notifications:[notification.id]}).subscribe((r) => {
          console.log(r);
          this._globalNumberNotificationService.numberNotifications = r.data;
          //this.numberNotifications = newNotificactions.number;
        });
    }

    ngOnInit() {

        let storedValue:any = localStorage.getItem('sidebar_mini_active');
        if (storedValue !== null) {
            storedValue = (storedValue === 'true');
            //console.log("storedValue => ", storedValue);
        }

        if(storedValue){
            this.minimizeSidebar();
        }

        let newtiming = new Date().getTime();

      this.myWebSocket.next({
        'registro': this.token,
        'time': newtiming
      });
    //   console.log("socket previo");
     this.myWebSocket.asObservable().subscribe(    
   msg => {
    let msgCoded = JSON.stringify(msg);
     //console.log('message received: ' + msgCoded);
     if(msg.numberNotifications && msg.numberNotifications != 'no'){
        //this.numberNotifications = msg.numberNotifications;
        this._globalNumberNotificationService.numberNotifications = msg.numberNotifications;
         if(typeof this.notificationsData === 'undefined')
            this.notificationsData = msg.data;
        else if(msg.data && msg.data[0] )
            this.notificationsData.unshift(msg.data[0]);
     }
    

  }, 
   // Called whenever there is a message from the server    
   err => {
    console.log(err);
    if(err.type == 'close'){
        console.log("se perdio conexion con websockets server");
    }
}, 
   // Called if WebSocket API signals some kind of error    
   () => console.log('complete') 
   // Called when connection is closed (for whatever reason)  
);



       this.privilegio = JSON.parse(localStorage.getItem("privilegio"));

        this.listTitles = ROUTES.filter(listTitle => listTitle);

        const navbar: HTMLElement = this.element.nativeElement;
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        if (body.classList.contains('sidebar-mini')) {
            misc.sidebar_mini_active = true;
        }
        if (body.classList.contains('hide-sidebar')) {
            misc.hide_sidebar_active = true;
        }
        this._router = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
          this.sidebarClose();

          const $layer = document.getElementsByClassName('close-layer')[0];
          if ($layer) {
            $layer.remove();
          }
        });


    }
    onResize(event) {
      if ($(window).width() > 991) {
        return false;
      }
      return true;
    }
    sidebarOpen() {
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const toggleButton = this.toggleButton;
        const body = document.getElementsByTagName('body')[0];
        setTimeout(function(){
            toggleButton.classList.add('toggled');
        }, 500);
        body.classList.add('nav-open');
        setTimeout(function() {
            $toggle.classList.add('toggled');
        }, 430);

        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');


        if (body.querySelectorAll('.main-panel')) {
            document.getElementsByClassName('main-panel')[0].appendChild($layer);
        }else if (body.classList.contains('off-canvas-sidebar')) {
            document.getElementsByClassName('wrapper-full-page')[0].appendChild($layer);
        }

        setTimeout(function() {
            $layer.classList.add('visible');
        }, 100);

        $layer.onclick = function() { //asign a function
          body.classList.remove('nav-open');
          this.mobile_menu_visible = 0;
          this.sidebarVisible = false;

          $layer.classList.remove('visible');
          setTimeout(function() {
              $layer.remove();
              $toggle.classList.remove('toggled');
          }, 400);
        }.bind(this);

        body.classList.add('nav-open');
        this.mobile_menu_visible = 1;
        this.sidebarVisible = true;
    };
    sidebarClose() {
      var $toggle = document.getElementsByClassName('navbar-toggler')[0];
        const body = document.getElementsByTagName('body')[0];
        this.toggleButton.classList.remove('toggled');
        var $layer = document.createElement('div');
        $layer.setAttribute('class', 'close-layer');

        this.sidebarVisible = false;
        body.classList.remove('nav-open');
        // $('html').removeClass('nav-open');
        body.classList.remove('nav-open');
        if ($layer) {
            $layer.remove();
        }

        setTimeout(function() {
            $toggle.classList.remove('toggled');
        }, 400);

        this.mobile_menu_visible = 0;
    };
    sidebarToggle() {
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    }

     logout() {
        localStorage.clear();
        this.router.navigate(['/']);
        ROUTES[ROUTES.length -1].children.pop();
        this._globalNumberNotificationService.numberNotifications = 0;
    }

    getTitle() {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if(titlee.charAt(0) === '#'){
          titlee = titlee.slice( 1 );
      }
        for (let i = 0; i < this.listTitles.length; i++) {
            if (this.listTitles[i].type === "link" && this.listTitles[i].path === titlee) {
                return this.listTitles[i].title;
            } else if (this.listTitles[i].type === "sub") {
                for (let j = 0; j < this.listTitles[i].children.length; j++) {
                    let subtitle = this.listTitles[i].path + '/' + this.listTitles[i].children[j].path;
                    // console.log(subtitle)
                    // console.log(titlee)
                    if (subtitle === titlee) {
                        return this.listTitles[i].children[j].title;
                    }
                }
            }
        }
        return 'Dashboard';
    }
    getPath() {
        return this.location.prepareExternalUrl(this.location.path());
    }
}
