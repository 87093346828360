import {Pipe, PipeTransform} from '@angular/core';
@Pipe ({
   name : 'Departments'
})
export class Departments implements PipeTransform {
   transform(val: string): string {

  
    let newStr = val.replace("MANIFESTDEPARTMENT", "Manifest");
     newStr = newStr.replace("TECHREP", "Tech");
     newStr = newStr.replace("SALESREP", "Sales");
     newStr = newStr.replace("RECEIVINGDEPARTMENT", "Receiving");

    return newStr;
   }
}