import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name:'Fechadb'})
export class Fechadb implements PipeTransform{
	transform(value): string{
		
		const words = value.split('-');
		
		return words[1]+'-'+words[2]+'-'+words[0];
		

	}
}