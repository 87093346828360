

        <div class="logo">
          <a style="width: 90%;" [routerLink]="['/'+url_redirect]" class="simple-text text-center">
          
                <img style="width: 150px; position: relative; margin: auto 0;" src={{this.logoCompany}} />
         
          </a>
          
        </div>


        <div class="sidebar-wrapper">

            <div class="user">
                <div class="photo">
                    <img src="../assets/img/faces/{{this.privilegio.avatar}}" />
                </div>
                <div class="user-info">
                    <a  [routerLink]="['/pages/profile/1']" class="collapsed">
                        <span>
                            {{this.privilegio.name}}
                            <b class="caret"></b>
                        </span>
                    </a>
               
                </div>
            </div>
            <div *ngIf="this.favorites.length > 0" class="user">
                <div class="photo-favorite">
                    <i class="material-icons" style="margin-left: 5px; margin-top: 5px;">favorite</i>
                </div>
                <div class="user-info">
                    <a data-toggle="collapse" href="#collapseFavorites" class="collapsed">
                        <span>
                            Favorites
                            <b class="caret"></b>
                        </span>
                    </a>
                    <div class="collapse" id="collapseFavorites">
                        <ul class="nav">
                            <li routerLinkActive="active" *ngFor="let childitem of favoriteItems" class="nav-item nav-content">
                                <a [routerLink]="[childitem.path]" class="nav-link">
                                    <!--<span class="sidebar-mini" style="margin-right: 28px;">{{childitem.ab}}</span>-->
                                    <span class="sidebar-normal" style="white-space: normal !important">{{childitem.title}}</span>
                                </a>
                                <span style="cursor: pointer; margin-bottom: -10px; font-weight: bold;"> 
                                    <i class="material-icons" *ngIf="favorites.includes(childitem.id)" (click)="removeFromFavorites(childitem.id)" style="font-size: 12px !important">favorite</i>
                                </span>
                                <!--<span style="font-size: 18px; cursor: pointer; margin-bottom: -10px; font-weight: bold; width: 27px;" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                                    >
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" (click)="removeFromFavorites(childitem.id)">Remove From Favorites</a>
                                    </div>
                                </span>-->
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
             <div *ngIf="(this.privilegio.unbot == '1' || this.identity.role == 'ADMINISTRATOR') && guard.permissions('uneda')" class="user">
        <div class="photo">
            <img src="../assets/img/ecommerce.png" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseUneda" class="collapsed">
                <span>
                    Uneda eMail Bot
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseUneda">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of unedaItems" class="nav-item nav-content">
                        <a [routerLink]="[childitem.path]" class="nav-link">
                            <!--<span class="sidebar-mini" style="margin-right: 28px;">{{childitem.ab}}</span>-->
                            <span class="sidebar-normal">{{childitem.title}}</span>
                        </a>
                        <span style="cursor: pointer; margin-bottom: -10px; font-weight: bold;"> 
                            <i class="material-icons" *ngIf="!favorites.includes(childitem.id)" (click)="addToFavorites(childitem.id)" style="font-size: 12px !important">favorite_border</i>
                            <i class="material-icons" *ngIf="favorites.includes(childitem.id)" (click)="removeFromFavorites(childitem.id)" style="font-size: 12px !important">favorite</i>
                        </span>
                        <!--<span style="font-size: 18px; cursor: pointer; margin-bottom: -10px; font-weight: bold; width: 27px;" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                            >
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" (click)="addToFavorites(childitem.id)" *ngIf="!favorites.includes(childitem.id)">Add To Favorites</a>
                                <a class="dropdown-item" (click)="removeFromFavorites(childitem.id)" *ngIf="favorites.includes(childitem.id)">Remove From Favorites</a>
                            </div>
                        </span>-->
                    </li>
                    <!--<li class="nav-item">
                        <a [routerLink]="['/pages/uploaduneda']" class="nav-link">
                            <span class="sidebar-mini">C</span>
                            <span class="sidebar-normal">Configuration</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/tables/listunedainventory']" class="nav-link">
                            <span class="sidebar-mini">MI</span>
                            <span class="sidebar-normal">My Inventory</span>
                        </a>
                    </li>-->


                </ul>
            </div>
        </div>
    </div>
    <div *ngIf="this.gobig && activeSection('eCommerce')" class="user">
        <!--<div *ngIf="this.privilegio.level == 'ADMINISTRATOR'" class="user">-->
        <div class="photo">
            <img src="../assets/img/ecommerce.png" />
        </div>
        <div class="user-info">
            <a data-toggle="collapse" href="#collapseEcommerce" class="collapsed">
                <span>
                    eCommerce
                    <b class="caret"></b>
                </span>
            </a>
            <div class="collapse" id="collapseEcommerce">
                <ul class="nav">
                    <li routerLinkActive="active" *ngFor="let childitem of ecommerceItems" class="nav-item nav-content">
                        <a [routerLink]="[childitem.path]" class="nav-link">
                            <!--<span class="sidebar-mini" style="margin-right: 28px;">{{childitem.ab}}</span>-->
                            <span class="sidebar-normal" style="white-space: normal !important">{{childitem.title}}</span>
                        </a>
                        <span style="cursor: pointer; margin-bottom: -10px; font-weight: bold;"> 
                            <i class="material-icons" *ngIf="!favorites.includes(childitem.id)" (click)="addToFavorites(childitem.id)" style="font-size: 12px !important">favorite_border</i>
                            <i class="material-icons" *ngIf="favorites.includes(childitem.id)" (click)="removeFromFavorites(childitem.id)" style="font-size: 12px !important">favorite</i>
                        </span>
                        <!--<span style="font-size: 18px; cursor: pointer; margin-bottom: -10px; font-weight: bold; width: 27px;" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"> 
                            >
                            <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <a class="dropdown-item" (click)="addToFavorites(childitem.id)" *ngIf="!favorites.includes(childitem.id)">Add To Favorites</a>
                                <a class="dropdown-item" (click)="removeFromFavorites(childitem.id)" *ngIf="favorites.includes(childitem.id)">Remove From Favorites</a>
                            </div>
                        </span>-->
                    </li>
                    <!--<li class="nav-item">
                        <a [routerLink]="['/gobig/ebayaccounts']" class="nav-link">
                            <span class="sidebar-mini">EA</span>
                            <span class="sidebar-normal">Ebay Accounts</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/ebayexporter']" class="nav-link">
                            <span class="sidebar-mini">ES</span>
                            <span class="sidebar-normal">Ebay Sync</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/tables/listebayitems']" class="nav-link">
                            <span class="sidebar-mini">SI</span>
                            <span class="sidebar-normal">Synced Items</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/ebaylistings']" class="nav-link">
                            <span class="sidebar-mini">EL</span>
                            <span class="sidebar-normal">Ebay Listings</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/ebaylocations']" class="nav-link">
                            <span class="sidebar-mini">EL</span>
                            <span class="sidebar-normal">Ebay Locations</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/ebayorders']" class="nav-link">
                            <span class="sidebar-mini">EO</span>
                            <span class="sidebar-normal">Ebay Orders</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/easytemplates']" class="nav-link">
                            <span class="sidebar-mini">ET</span>
                            <span class="sidebar-normal">Easy Templates</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/returnpolicies']" class="nav-link">
                            <span class="sidebar-mini">RP</span>
                            <span class="sidebar-normal">Return Policy</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/paymentpolicies']" class="nav-link">
                            <span class="sidebar-mini">PP</span>
                            <span class="sidebar-normal">Payment Policy</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/fulfillmentpolicies']" class="nav-link">
                            <span class="sidebar-mini">FP</span>
                            <span class="sidebar-normal">Fulfillment Policy</span>
                        </a>
                    </li>
                    <li *ngIf="this.identity.role!='EBAYTEST'" class="nav-item">
                        <a [routerLink]="['/gobig/viewinventory']" class="nav-link">
                            <span class="sidebar-mini">VI</span>
                            <span class="sidebar-normal">View Inventory</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/gobig/feedbackreminder']" class="nav-link">
                            <span class="sidebar-mini">FR</span>
                            <span class="sidebar-normal">Feedback Reminder</i></span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/gobig/awaitingfeedbackitems']" class="nav-link">
                            <span class="sidebar-mini">AF</span>
                            <span class="sidebar-normal">Awaiting Feedback Items</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/gobig/feedbacksreceived']" class="nav-link">
                            <span class="sidebar-mini">FR</span>
                            <span class="sidebar-normal">Feedbacks Received</span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a [routerLink]="['/gobig/offers']" class="nav-link">
                            <span class="sidebar-mini">OF</span>
                            <span class="sidebar-normal">Offers</span>
                        </a>
                    </li>-->
                </ul>
            </div>
        </div>
    </div>

            <div *ngIf="isMobileMenu()">
             <!-- <form class="navbar-form">
                <span class="bmd-form-group"><div class="input-group no-border">
                  <input type="text" value="" class="form-control" placeholder="Search...">
                  <button mat-raised-button type="submit" class="btn btn-white btn-round btn-just-icon">
                    <i class="material-icons">search</i>
                    <div class="ripple-container"></div>
                  </button>
                </div></span>
              </form>
              <ul class="nav navbar-nav nav-mobile-menu">
                <li class="nav-item">
                  <a class="nav-link" href="#ha">
                    <i class="material-icons">dashboard</i>
                    <p>
                      <span class="d-lg-none d-md-block">Stats</span>
                    </p>
                  </a>
                </li>
                <li class="nav-item dropdown">
                  <a class="nav-link" href="#ha" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <i class="material-icons">notifications</i>
                    <span class="notification">5</span>
                    <p>
                      <span class="d-lg-none d-md-block">Some Actions</span>
                    </p>
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                    <a class="dropdown-item" href="#">not</a>
                  
                  </div>
                </li>
                <li class="nav-item">
                  <a class="nav-link" href="#ha">
                    <i class="material-icons">person</i>
                    <p>
                      <span class="d-lg-none d-md-block">Account</span>
                    </p>
                  </a>
                </li>
              </ul>-->
            </div>
            <div id="accordion">
            <ul class="nav pb-5">
                <li routerLinkActive="active" *ngFor="let menuitem of menuItems" class="nav-item">
                    <!--If is a single link-->
                    <a  [routerLink]="[menuitem.path]" *ngIf="menuitem.type === 'link' && menuitem.visible" class="nav-link">
                        <i class="material-icons">{{menuitem.icontype}}</i>
                        <p>{{menuitem.title}}</p>
                    </a>
                    <!--If it have a submenu-->
                    <a data-toggle="collapse" href="#{{menuitem.collapse}}" id="{{menuitem.collapse}}-p" *ngIf="menuitem.type === 'sub' && menuitem.visible" (click)="updatePS();expandOrCollapseMenu(menuitem.collapse)" class="nav-link" aria-expanded="false" aria-controls="{{menuitem.collapse}}">
                        <i class="material-icons">{{menuitem.icontype}}</i>
                        <p>{{menuitem.title}}<b class="caret"></b></p>
                    </a>

                    <!--Display the submenu items-->
                    <div id="{{menuitem.collapse}}" class="collapse" aria-labelledby="{{menuitem.collapse}}-p" data-parent="#accordion">
                        <ul class="nav">
                            <li routerLinkActive="active" *ngFor="let childitem of menuitem.children" class="nav-item nav-content">
                                <a *ngIf="childitem.visible" [routerLink]="[childitem.path]" class="nav-link">
                                    <!--<span class="sidebar-mini" style="margin-right: 28px;">{{childitem.ab}}</span>-->
                                    <span class="sidebar-normal">{{childitem.title}}</span>
                                </a>
                                <span style="cursor: pointer; margin-bottom: -10px; font-weight: bold;" *ngIf="childitem.visible"> 
                                    <i class="material-icons" *ngIf="!favorites.includes(childitem.id)" (click)="addToFavorites(childitem.id)" style="font-size: 12px !important">favorite_border</i>
                                    <i class="material-icons" *ngIf="favorites.includes(childitem.id)" (click)="removeFromFavorites(childitem.id)" style="font-size: 12px !important">favorite</i>
                                </span>
                                <!--<span style="font-size: 18px; cursor: pointer; margin-bottom: -10px; font-weight: bold; width: 27px;" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="childitem.visible"> 
                                    >
                                    <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" (click)="addToFavorites(childitem.id)" *ngIf="!favorites.includes(childitem.id)">Add To Favorites</a>
                                        <a class="dropdown-item" (click)="removeFromFavorites(childitem.id)" *ngIf="favorites.includes(childitem.id)">Remove From Favorites</a>
                                    </div>
                                </span>-->
                            </li>
                        </ul>
                    </div>
                </li>
                <li *ngIf=" this.identity.role != 'CUSTOMER' && this.identity.role != 'VENDOR'" class="nav-item pb-4" routerLinkActive="active">
                    <div class="d-flex align-items-center justify-content-between">
                        <a class="nav-link btn btn-link text-left nav-item d-flex align-items-center" [routerLink]="[bugReporting[0].path]">
                            <i class="material-icons">bug_report</i>
                            <p style="width: 70%;">{{ bugReporting[0].title }}</p>
                        </a>
                        <span class="d-flex align-items-center" style="cursor: pointer; font-weight: bold;"> 
                            <i class="material-icons" *ngIf="!favorites.includes(bugReporting[0].id)" (click)="addToFavorites(bugReporting[0].id)" style="font-size: 12px !important">favorite_border</i>
                            <i class="material-icons" *ngIf="favorites.includes(bugReporting[0].id)" (click)="removeFromFavorites(bugReporting[0].id)" style="font-size: 12px !important">favorite</i>
                        </span>
                    </div>
                </li>
            </ul>
            </div>
             <ul class="nav">
            <li style="border-top: solid 1px #ccc" class="nav-item pb-4">
                <a class="nav-link btn btn-link text-left nav-item d-flex" (click)="logout()">
                    <i style="margin-top: 0px;" class="material-icons">logout</i>
                    <p style="width: 70%;">Sign Out</p>
                </a>
            </li>
        </ul>

        </div>
