// import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class GuiGuard {

    public constructor(protected authentication: UserService) { }

    public permissions(modulo): boolean {
        const user = this.authentication.getIdentity();
        const permissions = JSON.parse(user.shemale);
        const master = JSON.parse(user.masterPerm);
     

        if (
          modulo.includes('newPickup') ||
          modulo.includes('deleteScrap') ||
          modulo.includes('general-containers-type') ||
          modulo.includes('general-industry-types') ||
          modulo.includes('general-erasure-methods') ||
          modulo.includes('general-locations') ||
          modulo.includes('general-master-items') ||
          modulo.includes('general-selling-terms') ||
          modulo.includes('general-purchasing-terms') ||
          modulo.includes('general-product-category') ||
          modulo.includes('general-product-conditions') ||
          modulo.includes('general-tax-authorities') ||
          modulo.includes('general-system-company') ||
          modulo.includes('general-system-so') ||
          modulo.includes('general-system-po') ||
          modulo.includes('general-system-emailconfig') ||
          modulo.includes('general-system-cc') ||
          modulo.includes('general-system-integrations') ||
          modulo.includes('general-system-dynamictext') ||
          modulo.includes('general-system-workflowprogress') ||
          modulo.includes('general-notifications') ||
          modulo.includes('general-visitorslink') ||
          modulo.includes('general-configpto') ||
          modulo.includes('hdRep') ||
          modulo.includes('allEmployess') ||
          modulo.includes('revisePTO') ||
          modulo.includes('deleteHolyday') ||
          modulo.includes('edit-pi') ||
          modulo.includes('mark-completed') ||
          modulo.includes('testing-completed') ||
          modulo.includes('assignsalerep') ||
          modulo.includes('asaignmanifestrep') ||
          // modulo.includes('processing-instructions') ||
          modulo.includes('assigntechrep') ||
          modulo.includes('reserved-spaces') ||
          modulo.includes('edit-container') ||
          modulo.includes('mark-processed') ||
          modulo.includes('unlockPhDR') ||
          modulo.includes('mwaspandc2sc') ||
          modulo.includes('ViewPickup') ||
          modulo.includes('ViewBasicPickup') ||
          modulo.includes('ViewDelivery') ||
          modulo.includes('ViewCompany') ||
          modulo.includes('ViewPersonal') ||
          modulo.includes('ViewStaffPTO') ||
          modulo.includes('allRemReps') ||
          modulo.includes('changedepartmentlb') ||
          modulo.includes('create-mi-categories') ||
          modulo.includes('tableProcessingClientName') ||
          modulo.includes('inventoryReports') ||
          modulo.includes('inventoryReportExtraMissing') ||
          modulo.includes('inventoryReportExpenses') ||        
          modulo.includes('addrepresentative') ||
          modulo.includes('inventoryReportsDownloadManager')||
          modulo.includes('inventoryReportsComparative')||
          modulo.includes('returns')||
          modulo.includes('manifest-table')||
          modulo.includes('sales-table')||
          modulo.includes('tech-table')||
          modulo.includes('Assigned-po-view-all')
        ) {
          return this.checkModule(modulo, permissions);
        }

        return this.checkModule(modulo, permissions) && this.checkModule(modulo, master);
    }

    public checkModule(modulo, permissions): boolean {
        if (!permissions) {
            return false;
        }
        return permissions.indexOf(this.checkModuleToID(modulo)) >= 0;
    }

    public checkModuleToID(modulo) {
      
        if (modulo == 'inventoryhistory') {
          return 110; // Inventory History
        }
        else if (modulo == 'configpto') {
          return 111; // Tagging Groups
        }
        else if (modulo == 'agenda') {
          return 1; // Calendar
        } else if (modulo == 'listcompanies') {
          return 2; // Companies
        } else if (modulo == 'vendors') {
          return 3; // Clients
        } else if (modulo == 'listfiles') {
          return 4; // Files
        } else if (modulo == 'hdchecker') {
          return 5; // Erasure Finder
        } else if (modulo == 'listpagos') {
          return 6; // Payments
        } else if (modulo == 'listpersonal') {
          return 7; // Representatives
        } else if (modulo == 'purchase-order') {
          return 8; // Purchase Orders
        } else if (modulo == 'listcontactform') {
          return 9; // Contact Form
        } else if (modulo == 'trucks') {
          return 10; // Trucks
        } else if (modulo == 'sign-sheet') {
          return 11; // Visitors
        } else if (modulo == 'reports') {
          return 12; // Reports
        } else if (modulo == 'scrap-inventory') {
          return 13; // Scrap Inventory
        } else if (modulo == 'processing-instructions') {
          return 14; // Processing Instructions
        } else if (modulo == 'permissions') {
          return 15; // Permissions
        } else if (modulo == 'listlogs') {
          return 16; // Log's List
        } else if (modulo == 'mycontactforms') {
          return 17; // Log's List
        } else if (modulo == 'listcompanyfiles') {
          return 30;
        } else if (modulo == 'newPickup') {
          return 34;
        } else if (modulo == 'customers') {
          return 35;
        } else if (modulo == 'categories') {
          return 36; // User tag
        } else if (modulo == 'Ecommerce') {
          return 37; // GOBIG MODULE
        } else if (modulo == 'testing-record') {
          return 38; // Testing Record
        } else if (modulo == 'holidays') {
          return 40; // Holydays
        } else if (modulo == 'quickbooksconnect') {
          return 41; // Quickbooks
        } else if (modulo == 'hdRep') {
          return 42; // Quickbooks
        } else if (modulo == 'allEmployess') {
          return 43; // Quickbooks
        } else if (modulo == 'revisePTO') {
          return 44; // Revise PTO whith pay en Time off request
        } else if (modulo == 'deleteHolyday') {
          return 45; // Delete Time off request
        } else if (modulo == 'master-items') {
          return 46; // Master items
        } else if (modulo == 'psa') {
          return 47; // PSA
        } else if (modulo == 'warehouse') {
          return 48; // Warehouse
        } else if (modulo == 'manufacturer') {
          return 49; // Manufacturer
        } else if (modulo == 'master-types') {
          return 50; // Master Types
        } else if (modulo == 'productCategory') {
          return 51; // Inventory Category
        } else if (modulo == 'sales-orders') {
          return 52; // Sales Orders
        } /*else if (modulo == 'locations') {
          return 53; // Locations
        } */else if (modulo == 'notifications-perms') {
          return 54; // Notifications
        } else if (modulo == 'system-setup') {
          return 55; // System Setup
        } else if (modulo == 'sale-terms') {
          return 56; // Terms
        } else if (modulo == 'write-downs') {
          return 58; // Write Downs
        } else if (modulo == 'sync-quickbooks') {
          return 59; // Sync Quickbooks
        } else if (modulo == 'staff-pto') {
          return 60; // View Staff PTO
        } else if (modulo == 'questionnaire') {
          return 61; // Questionnaire
        } else if (modulo == 'edit-pi') {
          return 62; // Edit Processing Instructions
        } else if (modulo == 'change-status-pi') {
          return 63; // Change Status Processing Instructions
        } else if (modulo == 'container-inventory') {
          return 64; // Container Inventory
        } else if (modulo == 'tickets') {
          return 65; // Tickets
        } else if (modulo == 'insidelocation') {
          return 66; // insidelocation
        } else if (modulo == 'proposal') {
          return 67; // Purchase Proposal
        } else if (modulo == 'mark-completed') {
          return 68; // Mark manifest as completed
        } else if (modulo == 'reserved-spaces') {
          return 69; // Reserved Spaces
        } else if (modulo == 'logsSystem') {
          return 70; // Central Logs
        } else if (modulo == 'assignsalerep') {
          return 71; // Assign Sales Rep
        } else if (modulo == 'asaignmanifestrep') {
          return 72; // Assign Manifest Rep
        } else if (modulo == 'assigntechrep') {
          return 73; // Assign Tech Rep
        } else if (modulo == 'edit-container') {
          return 74; // Edit Container
        } else if (modulo == 'testing-completed') {
          return 75; // Mark testing as completed
        } else if (modulo == 'contactformblacklist') {
          return 76; // Contact Form Black List
        } else if (modulo == 'product-inventory') {
          return 78; // Product Inventory
        } else if (modulo == 'weightbalance') {
          return 79; // Weight Balance
        } else if (modulo == 'mark-processed') {
          return 80; // Mark PO as processed
        } else if (modulo == 'taxauthorities') {
          return 81; // Tax Authorities
        } else if (modulo == 'unlockPhDR') {
          return 82; // Unlock Physical Destruction Report
        } else if (modulo == 'uneda') {
          return 83; // Uneda eMail Bot
        } else if (modulo == 'eCommerce') {
          return 84; // eCommerce
        } else if (modulo == 'mwaspandc2sc') {
          return 85; // Mark weight as processed and create 2nd stage container
        } else if (modulo == 'anonymusreporting') {
          return 86; // Anonymous Reporting
        } else if (modulo == 'salesproposal') {
          return 87; // Sales Proposal
        } else if (modulo == 'ViewPickup') {
          return 88; // View Pickup
        } else if (modulo == 'ViewBasicPickup') {
          return 89; // View Basic Pickup
        } else if (modulo == 'ViewDelivery') {
          return 90; // View Delivery
        } else if (modulo == 'ViewCompany') {
          return 91; // View Company
        } else if (modulo == 'ViewPersonal') {
          return 92; // View Personal
        } else if (modulo == 'ViewStaffPTO') {
          return 93; // View Staff PTO
        } else if (modulo == 'purchase-terms') {
          return 94; // Purchassing Terms
        } else if (modulo == 'forms-reports') {
          return 95; // Forms Reports
        } else if (modulo == 'reminders') {
          return 96; // Reminders
        } else if (modulo == 'allRemReps') {
          return 97; // Show all reps reminders
        } else if (modulo == 'tableProcessingClientName') {
          return 98; // Show button 'client_name' in Table Processing
        } else if (modulo == 'priceHistory') {
          return 100; // Price History
        } else if (modulo == 'securaze') {
          return 101; // Securaze
        } else if (modulo == 'invoices') {
          return 102; // Invoices
        } else if (modulo == 'shipments') {
          return 103; // shipments
        } else if (modulo == 'statistics') {
          return 104; // Statistics
        } else if (modulo == 'workflow-process') {
          return 105; // Workflow Process
        } else if (modulo == 'centralizedfiles') {
          return 106; // Centralized Files
        } else if (modulo == 'visitorlink') {
          return 107; // Visitor Link
        } else if (modulo == 'erasuremethods') {
          return 108; // Erasure Methods
        } else if (modulo == 'tagging-groups') {
          return 109; // Tagging Groups
        } else if (modulo == 'containerstype') {
          return 112; // Containers Type
        } else if (modulo == 'changedepartmentlb') {
          return 113; // Containers Type
        }   else if (modulo == '/inventory') {
          return 99; // Inventory
        }  else if (modulo == '/visibilitytables') {
          return 114; // Inventory
        } else if (modulo == 'addrepresentative') {
          return 115; // Add Representative
        } else if (modulo == 'backgroundprocess') {
          return 116; // Background Processes
        } else if (modulo == '/inventoryReports') {
          return 118; // Inventory Reports
        } else if (modulo == '/inventoryReportExtraMissing') {
          return 119; // Inventory Report Extra & Missing
        } else if (modulo == '/inventoryReportExpenses') {
          return 120; // Inventory Report Expenses
        } else if (modulo == '/files-permissions-groups') {
          return 121; 
        } else if (modulo == 'create-mi-categories') {
          return 122; 
        } else if (modulo == 'general-containers-type') {
          return 123; 
        } else if (modulo == 'general-erasure-methods') {
          return 124; 
        } else if (modulo == 'general-locations') {
          return 125; 
        } else if (modulo == 'general-master-items') {
          return 126; 
        } else if (modulo == 'general-selling-terms') {
          return 127; 
        } else if (modulo == 'general-purchasing-terms') {
          return 128; 
        } else if (modulo == 'general-product-category') {
          return 129; 
        } else if (modulo == 'general-product-conditions') {
          return 130; 
        } else if (modulo == 'general-tax-authorities') {
          return 131; 
        } else if (modulo == 'general-industry-types') {
          return 132; 
        } else if (modulo == 'general-system-company') {
          return 133; 
        } else if (modulo == 'general-system-so') {
          return 134; 
        } else if (modulo == 'general-system-po') {
          return 135; 
        } else if (modulo == 'general-system-emailconfig') {
          return 136; 
        } else if (modulo == 'general-system-cc') {
          return 137; 
        } else if (modulo == 'general-system-integrations') {
          return 138; 
        } else if (modulo == 'general-system-dynamictext') {
          return 139; 
        } else if (modulo == 'general-system-workflowprogress') {
          return 140; 
        } else if (modulo == 'general-notifications') {
          return 141; 
        } else if (modulo == 'general-visitorslink') {
          return 142; 
        } else if (modulo == 'general-configpto') {
          return 143; 
        } else if (modulo == 'deleteScrap') {
          return 144; 
        } else if (modulo == 'inventoryReportsDownloadManager') {
          return 145; 
        } else if (modulo == 'inventoryReportsComparative') {
          return 146; 
        } else if (modulo == 'liveReporting/price-History') {
          return 147; 
        }else if (modulo == 'returns') {
          return 148; 
        }else if(modulo == 'manifest-table'){
          return 149; 
        }else if(modulo == 'sales-table'){
          return 150; 
        }else if(modulo == 'tech-table'){
          return 151; 
        }else if(modulo == 'Assigned-po-view-all'){
          return 152; 
        }

        return 0;
    }
}
