import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '../../services/user.service';

@Injectable()
export class BaseRouteGuard implements CanActivate {

    public constructor(protected authentication: UserService) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const user = this.authentication.getIdentity();
       // console.log("canActivate this.checkPermissions(state.url)", this.checkPermissions(state.url));
        return this.authentication.isAuthenticated() && (this.checkPermissions(state.url) || (user.role === 'ADMINISTRATOR'));
    }

    public checkPermissions(url): boolean {
     
        const user = this.authentication.getIdentity();
        const permissions = JSON.parse(user.shemale);
        const master = JSON.parse(user.masterPerm);
        
      
        //  console.log("this.checkModule(url, permissions) => ", this.checkModule(url, permissions));
       //   console.log("checkPermissions permissions => ", permissions);
       // console.log("checkPermissions url => ", url);
      //  console.log("checkPermissions this.checkModule(url, permissions) => ", this.checkModule(url, permissions));
      //console.log("checkPermissions this.checkModule(url, permissions) => ", this.checkModule(url, permissions));
      return this.checkModule(url, permissions) && this.checkModule(url, master);
    }

    public checkModule(url, modulos): boolean {
        if (!modulos) {
            return false;
        }
        //console.log("checkModule url => ", url);
        //console.log("checkModule this.changeUrlModuleToID(url) => ", this.changeUrlModuleToID(url));
        return modulos.indexOf(this.changeUrlModuleToID(url)) >= 0;
    }

    public changeUrlModuleToID(url) {
     
     
        if (url.includes('/agenda')) {
          return 1;
        } else if (url.includes('/tables/listcompanies')) {
          return 2;
        } else if (url.includes('/vendors')) {
          return 3;
        } else if (url.includes('/tables/listfiles')) {
          return 4;
        } else if (url.includes('/hdchecker')) {
          return 5;
        } else if (url.includes('/tables/listpagos')) {
          return 6;
        } else if (url.includes('/tables/listpersonal')) {
          return 7;
        } else if (url.includes('/purchase-order')) {
          return 8;
        } else if (url.includes('/tables/listcontactform')) {
          return 9;
        } else if (url.includes('/trucks')) {
          return 10;
        } else if (url.includes('/sign-sheet')) {
          return 11;
        } else if (url.includes('/reports')) {
          return 12;
        } else if (url.includes('/scrap-inventory')) {
          return 13;
        } else if (url.includes('/processing-instructions')) {
          return 14;
        } else if (url.includes('/permissions')) {
          return 15;
        } else if (url.includes('/tables/listlogs')) {
          return 16;
        } else if (url.includes('/mycontactforms')) {
          return 17;
        } else if (url.includes('/tables/listcompanyfiles')) {
          return 30;
        } else if (url.includes('/customers')) {
          return 35;
        } else if (url.includes('/categories')) {
          return 36; // User tag
        } else if (url.includes('/Ecommerce')) {
          return 37; // GOBIG MODULE
        } else if (url.includes('/testing-record')) {
          return 38; // Testing Record
        } else if (url.includes('/holidays')) {
          return 40; // Holydays
        } else if (url.includes('/quickbooksconnect')) {
          return 41; // Quickbooks
        } else if (url.includes('/master-items')) {
          return 46; // Master items
        } else if (url.includes('/psa')) {
          return 47; // PSA
        } else if (url.includes('/manufacturer')) {
          return 49; // Manufacturer
        } else if (url.includes('/master-types')) {
          return 50; // Master Types
        } else if (url.includes('/productCategory')) {
          return 51; // Inventory Category
        } else if (url.includes('/sales-orders')) {
          return 52; // Sales Orders
        } /*else if (url.includes('/locations')) {
          return 53; // Locations
        } */
        else if (url.includes('/warehouse')) {
          return 48; // Warehouse
        } else if (url.includes('/notifications-perms')) {
          return 54; // Notifications
        } else if (url.includes('/system-setup')) {
          return 55; // System Setup
        } else if (url.includes('/sale-terms')) {
          return 56; // Terms
        } else if (url.includes('/testing-record')) {
          return 57; // Testing Record
        } else if (url.includes('/write-downs')) {
          return 58; // Write Downs
        } else if (url.includes('/sync-quickbooks')) {
          return 59; // Sync Quickbooks
        } else if (url.includes('/questionnaire')) {
          return 61; // Questionnaire
        } else if (url.includes('/container-inventory')) {
          return 64; // Container Inventory
        } else if (url.includes('/tickets')) {
          return 65; // Tickets
        } else if (url.includes('/insidelocation')) {
          return 66; // insidelocation
        } else if (url.includes('/purchaseproposal')) {
          return 67; // Purchase Proposal
        } else if (url.includes('/logsSystem')) {
          return 70; // Central Logs
        } else if (url.includes('/contactformblacklist')) {
          return 76; // Contact Form Black List
        } else if (url.includes('/prouct-conditions')) {
          return 77; // Product Contitions
        } else if (url.includes('/product-inventory')) {
          return 78; // Product Inventory
        } else if (url.includes('/weightbalance')) {
          return 79; // Weight Balance
        } else if (url.includes('/taxauthorities')) {
          return 81; // Tax Authorities
        } else if (url.includes('/anonymusreporting')) {
          return 86; // Anonymous Reporting
        } else if (url.includes('/salesproposal')) {
          return 87; // Sales Proposal
        } else if (url.includes('/purchase-terms')) {
          return 94; // Purchassing Terms
        } else if (url.includes('/forms-reports')) {
          return 95; // Forms Reports
        } else if (url.includes('/reminders')) {
          return 96; // Reminders
        } else if (url.includes('/priceHistory')) {
          return 100; // Price History
        } else if (url.includes('/securaze')) {
          return 101; // Securaze
        } else if (url.includes('/invoices')) {
          return 102; // Invoices
        } else if (url.includes('/shipments')) {
          return 103; // Shipments
        } else if (url.includes('/statistics')) {
          return 104; // Statistics
        } else if (url.includes('/workflow-process')) {
          return 105; // Workflow Process
        } else if (url.includes('/centralizedfiles')) {
          return 106; // Centralized Files
        } else if (url.includes('/visitorlink')) {
          return 107; // Visitor Link
        } else if (url.includes('/erasuremethods')) {
          return 108; // Erasure Methods
        } else if (url.includes('/tagging-groups')) {
          return 109; // Tagging Groups
        } else if (url.includes('/inventoryhistory')) {
          return 110; // Inventory History
        } else if (url.includes('/configpto')) {
          return 111; // Config PTO
        } else if (url.includes('/containerstype')) {
          return 112; // Containers Type
        } else if (url.includes('/visibilitytables')) {
          return 114; // visibilitytables
        } else if (url.includes('/backgroundprocess')) {
          return 116; // Background Processes
        } else if (url.includes('/batch-container-inventory')) {
          return 117; // Batch Container Inventory
        } else if (url.includes('/inventoryReports')) {
          return 118; // Inventory Reports
        } else if (url.includes('/inventoryReportExtraMissing')) {
          return 119; // Inventory Report Extra & Missing
        } else if (url.includes('/inventoryReportExpenses')) {
          return 120; // Inventory Report Expenses
        } else if (url.includes('/inventory')) {
          return 99; // Inventory
        } else if (url.includes('/files-permissions-groups')) {
          return 121; 

        }else if (url.includes('/inventoryReportsDownloadManager')) {
          return 122; 
        }else if (url.includes('/inventoryReportsComparative')) {
          return 123; 
        } else if (url.includes('/inventoryReportsDownloadManager')) {
          return 145; 
        } else if (url.includes('/inventoryReportsComparative')) {
          return 146; 
        } else if (url.includes('/liveReporting/price-History')) {
          return 147; 
        }else if (url.includes('/returns')) {
          return 148; 
        }
        
        return 0;
    }
}

export interface ChangeUrlToPermissions {

    changeUrlModuleToID(url: string): number;
}
