import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url: any;
  private token: any | null;

  constructor(private httpClient: HttpClient, private jwtHelper: JwtHelperService) {
  
    this.url = GLOBAL.url;
    this.token = this.getToken();
  }

 public isAuthenticated(): boolean {
    return (
      typeof this.token !== "undefined" &&
      this.token !== null &&
      this.token !== ""
    );
  }


  ptoinfo(token: any, task: any, rep_id: any): Observable<any> {
    let json = JSON.stringify(task);
    json = encodeURIComponent(json);
    const params = `json=${json}&authorization=${token}`;
    const headers = this.getHeaders();
    const page = 1;

    return this.httpClient.post(`${this.url}/user/getptoinfo/${rep_id}?page=${page}`, params, { headers })
      .pipe(map(res => res));
  }

  signup(user_to_login: any): Observable<any> {
     let json = JSON.stringify(user_to_login);
    json = encodeURIComponent(json);
    const params = `json=${json}`;
    const headers = this.getHeaders();
    const url = `${this.url}/login`;

    return this.httpClient.post(url, params, { headers })
      .pipe(map(res => res));
  }

   phoneAuthentication(user_to_login: any): Observable<any> {
    let json = JSON.stringify(user_to_login);
    json = encodeURIComponent(json);
    const params = `json=${json}`;
    const headers = this.getHeaders();

    return this.httpClient.post(`${this.url}/phoneauth`, params, { headers })
      .pipe(map(res => res));
  }

  signMaster(user_to_login: any): Observable<any> {
  let json = JSON.stringify(user_to_login);
  json = encodeURIComponent(json);
  const params = `json=${json}`;
  const headers = this.getHeaders();

  return this.httpClient.post(`${this.url}/loginmaster`, params, { headers })
    .pipe(map(res => res));
}

getIdentity() {
  const identity = JSON.parse(localStorage.getItem('identity'));
  return identity !== undefined ? identity : null;
}

getAllUsers(token: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/alllist?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

getAllUsersType(token: any, type: any, page = 1, perpage: any = 'ALL', activos = 1,chat = 1,filtros: any[] = [],pageLimit:any = null): Observable<any> {
  const task = {
    active: String(activos),
    count_chat: chat,
    pagelimit: pageLimit,
  };
  let queryParams = `?page=${page}&perpage=${perpage}`;
  filtros.forEach(filtro => {
    if (filtro && filtro.value) {
      queryParams += `&${filtro.key}=${encodeURIComponent(filtro.value)}`;
      if (filtro.key_type) {
        queryParams += `&${filtro.key_type}=${encodeURIComponent(filtro.type)}`;
      }
    }
  });
  const endpoint = `/user/alllist/${type}${queryParams}`;
  const body = `json=${encodeURIComponent(JSON.stringify({ active: String(activos), count_chat: chat, pagelimit: pageLimit,}))}&authorization=${token}`;
 /*  let json = JSON.stringify(task);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${token}`; */
  const headers = this.getHeaders();

  return this.httpClient.post(`${this.url}${endpoint}`, body, { headers })
    .pipe(map(res => res));
}


setpagelimit(token: any, cant: any, dbname: any): Observable<any> {

  const task = { pageLimit: cant, dbName: dbname };
  const json = encodeURIComponent(JSON.stringify(task));
  const params = `json=${json}&authorization=${token}`;

  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/setpagelimit?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

setOauthconfig(token: any, data: any): Observable<any> {

  const json = encodeURIComponent(JSON.stringify(data));
  const params = `json=${json}&authorization=${token}`;

  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/oauthconfig`, params, { headers })
    .pipe(map(res => res));
}

getAllUsersForSO(token: any, so: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/alllistforsell/${so}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

getAllUsersForPO(token: any, po: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/alllistforpurchase/${po}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

getAllReps(token: any): Observable<any> {
  const task = { active: '1' };
  const json = encodeURIComponent(JSON.stringify(task));
  const params = `json=${json}&authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/alllist/REPS?page=${page}&perpage=ALL`, params, { headers })
    .pipe(map(res => res));
}

getUsersRoles(token: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/listroles?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


getAllUsersLite(token: any, type: any): Observable<any> {
  const params = `authorization=${this.getToken()}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/alllistlite/${type}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

getDepartaments(): Observable<any> {
  const params = `authorization=${this.getToken()}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/listreproles`, params, { headers })
    .pipe(map(res => res));
}



getAllUsersLiteByApiToken(token: any, type: any, apiToken: any): Observable<any> {
  const task = { apiToken: apiToken };
  let json = JSON.stringify(task);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/alllistlite/${type}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

sendwelcomeemail(token: any, id: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/sendwelcomeemail/${id}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


getUsers(token: any, page = 1, perpage: any = 'ALL', activos = 1): Observable<any> {
  const task = {
    active: String(activos)
  };
  let json = JSON.stringify(task);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${token}`;
  const headers = this.getHeaders();

  return this.httpClient.post(`${this.url}/user/list?page=${page}&perpage=${perpage}`, params, { headers })
    .pipe(map(res => res));
}

getUser(token: any, id: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/detail/${id}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

resetHolidays(token: any, id: any, task: any): Observable<any> {
  let json = JSON.stringify(task);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/resetholidays/${id}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


search(token: any, search = null, filter = null, order = null): Observable<any> {
  const params = `authorization=${token}&filter=${filter}&order=${order}`;
  const headers = this.getHeaders();
  const page = 1;
  const url = search == null ? `${this.url}/user/search?page=${page}` : `${this.url}/user/search/${search}?page=${page}`;

  return this.httpClient.post(url, params, { headers })
    .pipe(map(res => res));
}

register(user_to_register: any): Observable<any> {
  let json = JSON.stringify(user_to_register);
  json = encodeURIComponent(json);
  const params = `json=${json}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/new?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

deleteUser(token: any, id: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/remove/${id}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}



getEbayActiva(token: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/ebayactiva?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

update_user(user_to_update: any): Observable<any> {
  let json = JSON.stringify(user_to_update);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${this.getToken()}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/edit?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


update_user_permission(user_to_update: any): Observable<any> {
  let json = JSON.stringify(user_to_update);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${this.getToken()}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/edituserpermission?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


create(token: any, task: any): Observable<any> {
  let json = JSON.stringify(task);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/new?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

changerep(token: any, task: any): Observable<any> {
  let json = JSON.stringify(task);
  json = encodeURIComponent(json);
  const page = 1;
  const params = `json=${json}&authorization=${token}`;
  const headers = this.getHeaders();

  return this.httpClient.post(`${this.url}/user/changerep?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


delegate(token: any, task: any): Observable<any> {
  let json = JSON.stringify(task);
  json = encodeURIComponent(json);
  const page = 1;
  const params = `json=${json}&authorization=${token}`;
  const headers = this.getHeaders();

  return this.httpClient.post(`${this.url}/user/delegate?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

update_user_remoto(user_to_update: any): Observable<any> {
  let json = JSON.stringify(user_to_update);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${this.getToken()}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/pacienteeditar?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

chPassword(user_to_update: any): Observable<any> {
  let json = JSON.stringify(user_to_update);
  json = encodeURIComponent(json);
  const params = `json=${json}&authorization=${this.getToken()}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/changepassword?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


sendRecoveryPass(email: any): Observable<any> {
  let json = JSON.stringify(email);
  json = encodeURIComponent(json);
  const params = `json=${json}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/passwordpost/reset?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

resetMFA(email: any): Observable<any> {
  let json = JSON.stringify(email);
  json = encodeURIComponent(json);
  const params = `json=${json}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/resetmfa?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

addToFavorites(token: any, id: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/addtofavorites/${id}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

removeFromFavorites(token: any, id: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/removefromfavorites/${id}?page=${page}`, params, { headers })
    .pipe(map(res => res));
}

getFavorites(token: any): Observable<any> {
  const params = `authorization=${token}`;
  const headers = this.getHeaders();
  const page = 1;

  return this.httpClient.post(`${this.url}/user/getfavorites?page=${page}`, params, { headers })
    .pipe(map(res => res));
}


  public getToken(): any | null {
    const token = localStorage.getItem('token');
    return token ? JSON.parse(token) : null;
  }

  public  getHeaders(): HttpHeaders {
    const headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return headers;
  }
}
