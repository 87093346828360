import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Fechagts } from '../pipes/fechagts.pipe';
import { Fechadb } from '../pipes/fechadb.pipe';
import { Departments } from '../pipes/departments.pipe';
import { Limpiarguion } from '../pipes/limpiarguion.pipe';
import { SafeHtml } from '../pipes/safeHtml.pipe';
import { Showstage } from '../pipes/showstage.pipe';
import { CapitalizeFirstPipe } from '../pipes/capitalize-first.pipe';
import { CustomTextMapper } from '../pipes/customTextMapper.pipe';
import { RelativeTimePipe } from '../pipes/relative-time.pipe';
import { CustomTooltipDirective } from './directives/custom-tooltip/custom-tooltip.directive';
//import { Verifyebayitem } from '../pipes/verifyebayitem.pipe';


@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [Fechagts, Fechadb, Limpiarguion, Showstage,  Departments, SafeHtml, CapitalizeFirstPipe, CustomTextMapper, RelativeTimePipe, CustomTooltipDirective],
  exports: [Fechagts, Fechadb, Limpiarguion, Showstage, Departments, SafeHtml, CapitalizeFirstPipe, CustomTextMapper, RelativeTimePipe, CustomTooltipDirective],
})
export class SharedModule { }