import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'relativeTime'
})
export class RelativeTimePipe implements PipeTransform {
  
  transform(value: any): any {
    let result = moment(value).fromNow();
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

}
